













































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Debounce from "lodash/debounce";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import IconLeft from "@/components/icons/icon-left.vue";
import IconLeft2 from "@/components/icons/icon-left2.vue";
import IconRight from "@/components/icons/icon-right.vue";
import IconRight2 from "@/components/icons/icon-right2.vue"
import IconCloseCircleS from "@/components/icons/icon-close-circle-s.vue";
import IconSearch from "@/components/icons/icon-search.vue";
import IconSearch2 from "@/components/icons/icon-search2.vue";
import HereArrow from "@/components/illustrations/here-arrow.vue";
import { EventsState } from "@/store/modules/events";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "SearchEvent",
  props: {
    key_: String,
    isBig: Boolean,
  },
  components: {
    BaseIcon,
    BaseIllustration,
    IconLeft,
    IconLeft2,
    IconRight,
    IconRight2,
    IconCloseCircleS,
    IconSearch,
    IconSearch2,
    HereArrow,
  },
})
export default class SearchEvent extends BaseComponent {

  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);
  
  debouncedDoSearch: any = null;

  eventName: string = "";

  inputElementId: string = "";

  found: any[] = [];
  recent: any[] = [];

  showList: boolean = false;
  showNotFound: boolean = false;
  showRecent: boolean = true;
  selected: boolean = false;

  pageOffset: { x: number, y: number } = { x: -1, y: -1 };

  get theme(): any {
    return this.settingsState.theme;
  }

  get key(): string {
    return this.$props.key_;
  }

  get events(): any[] {
    return this.eventsState.events;
  }

  showFiltered(value: any): string {
    if (this.eventName == "") return "";

    const escaped: string = this.escapeRegExpChars(this.eventName);
    const pattern: RegExp = new RegExp(escaped, "i");
    
    let title: string = this.formatEventTitle(value);
    const result = title.replace(pattern, 
      "<span class='font-weight-bold'>" + 
      "$&"+
      "</span>");

    return result;
  }

  escapeRegExpChars(regexp: string): string {
    if (regexp == "") return "";

    const chars: string[] = ["\\", "/", "[", "^", "$", ".", "|", "?", "*", "+", "(", ")"];
    
    let result: string = regexp;    
    for (let ch of chars) {
      result = result.replace(new RegExp("\\" + ch, "i"), "\\" + ch);
    }
    
    return result;
  }

  formatEventTitle(value: any): string {
    let date: string = value.date.slice(0, 10);
    date = this.formatDate(date);

    return value.title + " (" + Moment.utc(date).format("DD.MM.YYYY") + ")";
  }

  formatDate(date: string): string {
    if (date.charAt(2) == ".") {
      return date.slice(6, 10) + "-" + date.slice(3, 5) + "-" + date.slice(0, 2);
    }
    return date;
  }

  getIconStyle(): any {
    if (!this.smOnly && !this.mdOnly) {
      return { 'right': '14px' };
    } else if (this.smOnly || this.mdOnly) {
      return { 'right': '17px' };
    } else {
      return '';
    }
  }

  async isTagged(item: any): Promise<boolean> {
    if (!item) return false;
    await this.eventsState.getEventById(item.eventId);
    return this.eventsState.event.searchingPhotos.byTag === true;
  }

  @Watch("key")
  async onKeyChanged(): Promise<void> {
    await this.scrollTop();
    await this.loadRecentSearches();
    this.$emit('changed', this.showList);
    setTimeout(() => { this.setFocusToInput(); }, 100);
  }

  async loadRecentSearches(): Promise<void> {
    const itemName = "eventRecentSearch";
    const value = localStorage.getItem(itemName);
    if (value) {
      try {
        const parsed: any[] = JSON.parse(value);
        this.recent = this.getGroupedByEvent(parsed);
      } catch (e) {
        this.recent = [];
      }
    } else {
      this.recent = [];
    }
  }

  getGroupedByEvent(parsed: any[]): any[] {
    const grouped: any[] = [];

    for (let i = 0; i < parsed.length; i++) {
      if (grouped.length == 0) {
        grouped.push({ event: parsed[i].event, searches: [parsed[i].search] });
        continue;
      }

      if (grouped.length == 1 && grouped[0].event.eventId == parsed[i].event.eventId) {
        if (grouped[0].searches.length < 3) {
          grouped[0].searches.push(parsed[i].search);
          continue;
        } else {
          continue;
        }
      }

      if (grouped.length == 1 && grouped[0].event.eventId != parsed[i].event.eventId) {
        grouped.push({ event: parsed[i].event, searches: [parsed[i].search] });
        continue;
      }

      const isFirst: boolean = grouped[0].event.eventId == parsed[i].event.eventId;
      const isSecond: boolean = grouped[1].event.eventId == parsed[i].event.eventId;
      
      if (grouped.length == 2 && (isFirst || isSecond)) {
        const index: number = isFirst ? 0 : 1;
        if (grouped[index].searches.length < 3) {
          grouped[index].searches.push(parsed[i].search);
          continue;
        } else {
          continue;
        }
      }

      if (grouped.length == 2 && (!isFirst && !isSecond)) {
        continue;
      }
    }

    return grouped;
  }

  async onGoEvent(item: any): Promise<void> {
    if (!item) return;

    this.settingsState.reachGoal('event_selected_from_search');

    await this.$router.push({
      name: "event",
      params: {
        id: item.event.externalEventId,
      }
    })
  }

  async onGoRecent(item: any, search: any): Promise<void> {
    if (!item || !search) return;

    this.settingsState.reachGoal('recently_search');
    this.settingsState.reachGoal(search.faceUrl ? 'media_selfie_uploaded' : 'media_member_selected');

    await this.$router.push({
      name: "search-from-url",
      params: {
        id: item.event.externalEventId,
        number: search.faceUrl ? search.selfieId + '+' + search.personId : search.startNumber,
      }
    });
  }

  async onTextChanged(value: any): Promise<void> {
    if (value != '') {
      this.showRecent = false;
      this.debouncedDoSearch();
    } else {
      this.showList = false;
      this.showRecent = true;
      this.$emit('changed', this.showList);
    }
  }

  async onClearText(): Promise<void> {
    this.eventName = "";
    this.showList = false;
    this.$emit('shown', true);
    setTimeout(() => {
        this.showRecent = true;
        this.$emit('changed', this.showList);
        if (this.selected) {
          this.$emit('cancelled');
        }
      },
      100,
    );
  }

  async onClick(): Promise<void> {
    const e = document.getElementById(this.inputElementId);
    if (e) e.focus();

    if (this.smOnly || this.mdOnly) return;
    
    this.$emit('shown', true);

    if (!this.showList) {
      this.selected = false;
      await this.doSearch();
    }
  }

  async onBlur(): Promise<void> {
    if (this.smOnly || this.mdOnly) return;

    setTimeout(async () => {
        if (this.selected || this.found.length > 0) return;
        await this.scrollBack();
        this.showList = false;
        this.showRecent = false;
        this.$emit('changed', this.showList);
        this.$emit('shown', false);
      },
      250,
    );
  }

  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
  }

  async doSearch(): Promise<void> {
    if (this.eventName.length == 0) {
      this.showList = false;
      this.showNotFound = false;
      this.showRecent = true;
      this.$emit('changed', this.showList);
      return;
    }

    const payload: any = {
      search: this.eventName,
      pagination: { offset: 0, count: 100 },
    }

    await this.eventsState.getEvents(payload);
    let found: any[] = this.eventsState.events;

    if (this.smOnly || this.mdOnly) {
      if (found.length > 10) {
        found = found.slice(0, 10);
      }
    } 
    this.found = found;
    
    if (this.found.length > 0) {
      this.showNotFound = false;
      this.showList = true;
    } else {
      this.showNotFound = true;
      this.showList = true;
    }

    setTimeout(() => {
        this.$emit('changed', this.showList);
      },
      100,
    );
  }

  async onSelected(item: any): Promise<void> {
    item["tagged"] = await this.isTagged(item);
    item.date = this.formatDate(item.date);
    this.selected = true;
    this.eventName = item.title;
    this.$emit('selected', item);
  }

  async onCancel(): Promise<void> {
    this.$emit('cancelled');
    this.resetValues();
    await this.scrollBack();
  }

  async resetValues(): Promise<void> {
    this.eventName = "";
    this.found = [];
    this.showList = false;
    this.selected = false;
    this.showRecent = true;
    this.$emit('changed', this.showList);
  }

  async scrollTop(): Promise<void> {
    if (this.pageOffset.x == -1 && this.pageOffset.y == -1) {
      this.pageOffset = { x: window.pageXOffset, y: window.pageYOffset };
    }

    if (this.smOnly || this.mdOnly) {
      setTimeout(() => { window.scrollTo(0, 0); }, 50);
    }
  }

  async scrollBack(): Promise<void> {
    if (this.smOnly || this.mdOnly) {
      setTimeout(() => {
          window.scrollTo(this.pageOffset.x, this.pageOffset.y);
        },
        250
      );
    }
  }

  setFocusToInput(): void {
    const e = document.getElementById(this.inputElementId);
    if (e === null) {
      setTimeout(() => { this.setFocusToInput(); }, 50);
      return;
    }
    setTimeout(() => {
      e.focus();
      e.click();
    }, 100);
  }

  created(): void {
    this.debouncedDoSearch = Debounce(this.doSearch, 500);
  }

  async mounted(): Promise<void> {
    this.inputElementId = "search_event_input_" + Math.random().toString();
    await this.scrollTop();
    await this.loadRecentSearches();
  }

}
